import * as actionsTypes from './actionTypes';
import axios from 'axios';

const defaultLanguage = 'en';

export const retrieveCountryDataStart = () => {
  return {
    type: actionsTypes.RETRIEVE_COUNTRY_DATA_START,
  };
};

export const retrieveCountryDataSuccess = ( countryData ) => {
  return {
    type: actionsTypes.RETRIEVE_COUNTRY_DATA_SUCCESS,
    countryData,
  };
};


export const retrieveCountryDataFail = ( error ) => {
  return {
    type: actionsTypes.RETRIEVE_COUNTRY_DATA_FAIL,
    error
  };
};

export const formatCountryData = ( countryId, countryData ) => {

  const pathCountryFolder = `${ process.env.CONTENT_LOCAL }/countries/${ countryId }`;

  countryData.pathLocationVideoUrl = `${ pathCountryFolder }/${ countryData.locationVideoUrl }`;

  // if ( typeof countryData.locationFlag )
  countryData.pathLocationFlag = countryData.locationFlag.map( ( locationFlag ) => `${ pathCountryFolder }/${ locationFlag }` );

  // countryData.pathLocationFlag = `${ pathCountryFolder }/${ countryData.locationFlag }`;
  countryData.allPhotosPath = `${ pathCountryFolder }/${ countryData.allPhotosPath }`;

  Object.values( countryData.pictures ).forEach( ( picture ) => {
      picture.originalBig = `${ pathCountryFolder }/${ picture.originalBig }`;
      picture.originalSmall = `${ pathCountryFolder }/${ picture.originalSmall }`;
      picture.thumbnail = `${ pathCountryFolder }/${ picture.thumbnail }`;
    }
  );

  return countryData;
};

export const retrieveCountryData = ( countryId, lang ) => {
  return dispatch => {
    dispatch( retrieveCountryDataStart( lang ) );


    const pathJson = `${ process.env.CONTENT_LOCAL }/countries/${ countryId }/${ countryId }_${ lang }.json`;
    // const pathJson = `${ process.env.CONTENT_LOCAL }/countries/${ countryId }/${ countryId }_${ lang }.json`;

    axios.get( pathJson )
      .then( ( response ) => {
        console.log( '[retrieveCountryData actions] Success response=', response );

        const formattedCountry = formatCountryData( countryId, response.data );
        dispatch( retrieveCountryDataSuccess( formattedCountry ) );
      } )
      .catch( ( error ) => {
        console.log( 'error during retrieveCountryData error=', error );
        if ( error.response.status === 404 && lang !== defaultLanguage ) {
          // Default back to english if the other language is not found
          console.error( 'error 404 during retrieveCountryData, retrieve english version error=', error.response );

          const pathJsonDefaultLanguage = `${ process.env.CONTENT_LOCAL }/countries/${ countryId }/${ countryId }_${ defaultLanguage }.json`;
          axios.get( pathJsonDefaultLanguage )
            .then( ( response ) => {
              console.log( '[retrieveCountryData actions] Success response.data=', response.data );
              const formattedCountry = formatCountryData( countryId, response.data );
              dispatch( retrieveCountryDataSuccess( formattedCountry ) );
            } )
            .catch( ( error ) => {
              console.log( 'error during retrieveCountryData error=', error.response );
              dispatch( retrieveCountryDataFail( error ) );
            } );
        }
        else {
          dispatch( retrieveCountryDataFail( error ) );
        }
      } );
  };
};


