import * as actionsTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const INGREDIENTS_PRICES = {
  salad: 0.5,
  cheese: 0.4,
  meat: 1.3,
  bacon: 0.7
};

const initialState = {
  ingredients: null,
  totalPrice: 4,
  error: false,
  building: false,
};

const addIngredient = ( state, action ) => {
  const updatedIngredient = {
    [ action.ingredientName ]: state.ingredients[ action.ingredientName ] + 1
  };
  const updatedIngredients = updateObject( state.ingredients, updatedIngredient );
  const updatedState = {
    building: true,
    ingredients: updatedIngredients,
    totalPrice: parseFloat( ( state.totalPrice + INGREDIENTS_PRICES[ action.ingredientName ] ).toFixed( 10 ) )
  };

  return updateObject( state, updatedState );
};

const removeIngredient = ( state, action ) => {
  const updatedIng = {
    [ action.ingredientName ]: state.ingredients[ action.ingredientName ] - 1
  };
  const updatedIngs = updateObject( state.ingredients, updatedIng );
  const updatedSt = {
    building: true,
    ingredients: updatedIngs,
    totalPrice: parseFloat( ( state.totalPrice - INGREDIENTS_PRICES[ action.ingredientName ] ).toFixed( 10 ) )
  };

  return updateObject( state, updatedSt );
};


const setIngredients = ( state, action ) => {
  return updateObject( state, {
    ingredients: {
      salad: action.ingredients.salad,
      bacon: action.ingredients.bacon,
      cheese: action.ingredients.cheese,
      meat: action.ingredients.meat,
    },
    totalPrice: initialState.totalPrice,
    error: false,
    building: false,
  } );
};

const fetchIngredientsFailed = ( state, action ) => {
  return updateObject( state, {
    error: true,
  } );
};


const burgerBuilder = ( state = initialState, action ) => {

  switch ( action.type ) {
    case actionsTypes.ADD_INGREDIENT: return addIngredient( state, action );
    case actionsTypes.REMOVE_INGREDIENT: return removeIngredient( state, action );
    case actionsTypes.SET_INGREDIENTS: return setIngredients( state, action );
    case actionsTypes.FETCH_INGREDIENTS_FAILED: return fetchIngredientsFailed( state, action );

    default: return state
  }

};

export default burgerBuilder;
