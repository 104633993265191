import React from 'react';
import PropTypes from 'prop-types';
import classes from './Backdrop.module.scss';

const backdrop = ( props ) => (
  props.show ? <div onClick={props.clicked} className={classes.Backdrop}></div> : null
);

backdrop.propTypes = {
  show: PropTypes.bool,
  clicked: PropTypes.func,
};

export default backdrop;
