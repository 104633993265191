import React from 'react';
import classes from './ChangeLanguage.module.scss';
import flagFr from '../../../assets/images/flag_fr.png';
import flagEn from '../../../assets/images/flag_gb.png';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';

const ChangeLanguage = ( props ) => {

  const changeLanguageHandler = ( language ) => {
    props.onLanguageChange( language );
  };

  const languages = [
    {
      id: 'fr',
      flag: flagFr,
      alt: 'French',
      text: 'FR'
    },
    {
      id: 'en',
      flag: flagEn,
      alt: 'English',
      text: 'EN'
    },
  ];

  const listLanguages = languages.map( (language) => {

    const cssClassesLanguage = [ classes.Language ];
    if ( props.lang === language.id ) {
      cssClassesLanguage.push( classes.Active )
    }

    return (
      <div key={ language.id } onClick={ () => changeLanguageHandler( language.id ) } className={ cssClassesLanguage.join( ' ' ) }>
        <img src={ language.flag } alt={ language.alt }/>
        <p>{ language.text }</p>
      </div>
    );
  } );


    return (
      <div className={ classes.ChangeLanguage }>
        { listLanguages }
      </div>
    );
};

ChangeLanguage.propTypes = {};


const mapStateToProps = state => {
  return {
    lang: state.language.lang,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onLanguageChange: ( language ) => dispatch( actions.changeLanguage( language ) ),
  }
};

export default connect( mapStateToProps, mapDispatchToProps )( ChangeLanguage );
