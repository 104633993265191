import React from 'react';
import PropTypes from 'prop-types';
import classes from './Flag.module.scss';

import flag_us from 'assets/flags/us.png';
import flag_belize from 'assets/flags/belize.png';
import flag_bolivia from 'assets/flags/bolivia.png';
import flag_peru from 'assets/flags/peru.png';
import flag_ecuador from 'assets/flags/ecuador.png';
import flag_colombia from 'assets/flags/colombia.png';
import flag_panama from 'assets/flags/panama.png';
import flag_guatemala from 'assets/flags/guatemala.png';
import flag_chile from 'assets/flags/chile.png';
import flag_mexico from 'assets/flags/mexico.png';
import flag_southKorea from 'assets/flags/southKorea.png';
import flag_japan from 'assets/flags/japan.png';
import flag_taiwan from 'assets/flags/taiwan.png';
import flag_hongkong from 'assets/flags/hongkong.gif';
import flag_india from 'assets/flags/india.png';
import flag_vietnam from 'assets/flags/vietnam.png';
import flag_laos from 'assets/flags/laos.png';
import flag_thailand from 'assets/flags/thailand.png';
import flag_myanmar from 'assets/flags/myanmar.png';

const Flag = ( props ) => {

  const countryIdToFlag = {
    guatemala: flag_guatemala,
    chile: flag_chile,
    bolivia: flag_bolivia,
    peru: flag_peru,
    ecuador: flag_ecuador,
    colombia: flag_colombia,
    panama: flag_panama,
    belize: flag_belize,
    mexico: flag_mexico,
    usaWestCoast: flag_us,
    usaNationalParks: flag_us,
    southKorea: flag_southKorea,
    japan: flag_japan,
    taiwan: flag_taiwan,
    hongKongMacau: flag_hongkong,
    india: flag_india,
    vietnam: flag_vietnam,
    laos: flag_laos,
    thailandIsland: flag_thailand,
    thailandRemi: flag_thailand,
    myanmar: flag_myanmar,
  };

  let customStyle = {
    width: props.width ? `${ props.width }px` : 'auto'
  };

  return (
    <div className={ classes.Flag }>
      <img style={ customStyle }
           src={ countryIdToFlag[ props.countryId ] }
           alt={ `Flag from ${ props.countryId }` }/>
    </div>
  );
};

Flag.propTypes = {
  countryId: PropTypes.string,
  width: PropTypes.number,
};

export default Flag;
