import React from 'react';
import PropTypes from 'prop-types';

import letspackLogo from '../../assets/images/letspack-logo.png';
import classes from './Logo.module.scss';

const logo = ( props ) => (
  <div className={classes.Logo} style={{ height: props.height }}>
    <img src={letspackLogo} alt="Letspack Logo"/>
  </div>
);

logo.propTypes = {
  height: PropTypes.string
};

export default logo;
