import React, { useState } from 'react';
import Aux from '../../hoc/Aux/Aux';
import classes from './Layout.module.scss';
import letspackTextLogo from '../../assets/images/letspack-text-logo.png';

import { connect } from 'react-redux';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

import { allCountriesObject } from '../../shared/countryList';

const Layout = ( props ) => {

  const [ showSideDrawer, setShowSideDrawer ] = useState( false );

  const sideDrawerClosedHandler = () => {
    setShowSideDrawer( false );
  };

  const sideDrawerToggleHandler = () => {
    setShowSideDrawer( !showSideDrawer );
  };

  return (
    <Aux>
      <SideDrawer
        allCountries={ allCountriesObject }
        isAuth={ props.isAuthenticated }
        showSideDrawer={ showSideDrawer }
        closed={ sideDrawerClosedHandler }/>
      <Toolbar
        allCountries={ allCountriesObject }
        isAuth={ props.isAuthenticated }
        toggleSideDrawer={ sideDrawerToggleHandler }/>
      <main className={ classes.Content }>
        { props.children }
      </main>
      <div className={ classes.Footer }>
        <img src={ letspackTextLogo } alt='letspack'/>
      </div>
    </Aux>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  }
};


export default connect( mapStateToProps )( Layout );

