import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import BackDrop from '../../UI/Backdrop/Backdrop';
import Aux from '../../../hoc/Aux/Aux';

import classes from './SideDrawer.module.scss';
import Collapse from 'react-bootstrap/Collapse';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SideDrawer = ( props ) => {

  const { t } = useTranslation();

  const [ isOpen, setIsOpen ] = useState( {} );
  const [ countriesPerContinent, setCountriesPerContinent ] = useState( {} );

  const { allCountries } = props;
  useEffect( () => {

    const countriesPerContinentTemp = {};
    allCountries.forEach( ( country ) => {
      const currentContinentId = country.continentId;
      if ( !countriesPerContinentTemp.hasOwnProperty( currentContinentId ) ) {
        countriesPerContinentTemp[ currentContinentId ] = [];
      }

      countriesPerContinentTemp[ currentContinentId ].push( country );
    } );
    setCountriesPerContinent( countriesPerContinentTemp );

  }, [ allCountries ] );

  const onButtonClickHandler = ( event, continentIdToShow ) => {
    console.log( `[SideDrawer] onButtonClickHandler event=`, event, '  continentIdToShow=', continentIdToShow );
    event.stopPropagation();
    setIsOpen( ( currentIsOpenState ) => {

      if ( currentIsOpenState[ continentIdToShow ] ) {
        // Hide everything if the current one is open
        return {};
      }

      return {
        [ continentIdToShow ]: true
      };
    } );
  };

  let attachedClasses = [ classes.SideDrawer, classes.Close ];
  if ( props.showSideDrawer ) {
    attachedClasses = [ classes.SideDrawer, classes.Open ];
  }

  console.log( '[SideDrawer] - constructor' );
  const continentList = [];
  for ( let [ continentId, countryObjectList ] of Object.entries( countriesPerContinent ) ) {
    continentList.push( (
      <div className={ classes.Continent } key={ continentId }>
        <div className={ classes.TitleContinent }
             onClick={ ( event ) => onButtonClickHandler( event, continentId ) }
             aria-controls={ continentId }
             aria-expanded={ isOpen[ continentId ] }>
          <h4>
            { t( `CONTINENTS.${ continentId }` ) }
          </h4>
          <FontAwesomeIcon icon={ faChevronDown }
                           className={ classes.IconTitle }
                           rotation={ isOpen[ continentId ] ? 180 : null } />
        </div>
        <Collapse in={ isOpen[ continentId ] }>
          <div id={ continentId }>
            <NavigationItems
              countries={ countryObjectList }
              toolBarDisplay={ false }
              isAuthenticated={ props.isAuth }/>
          </div>
        </Collapse>
      </div>
    ) )
  }

  return (
    <Aux>
      <BackDrop show={ props.showSideDrawer } clicked={ props.closed }/>
      <div className={ attachedClasses.join( ' ' ) } onClick={ props.closed }>
        <div className={ classes.Logo }>
          <NavLink
            exact
            to='/'>
            <Logo/>
          </NavLink>
        </div>
        <nav>
          { continentList }
        </nav>
      </div>
    </Aux>
  );
};

SideDrawer.propTypes = {
  closed: PropTypes.func,
  showSideDrawer: PropTypes.bool,
  isAuth: PropTypes.bool,
  allCountries: PropTypes.array,
};

export default SideDrawer;
