import * as actionsTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

export const initialState = {
  countryData: null,
  language: null,
  loading: false,
  error: null,
};

const retrieveCountryDataStart = ( state, action ) => {
  return updateObject( state, {
    countryData: null,
    language: action.language,
    loading: true,
    error: null
  } );
};

const retrieveCountryDataSuccess = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    countryData: action.countryData,
    error: null,
  } );
};

const retrieveCountryDataFail = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    error: action.error
  } );
};


const countryData = ( state = initialState, action ) => {

  switch ( action.type ) {
    case actionsTypes.RETRIEVE_COUNTRY_DATA_START: return retrieveCountryDataStart( state, action );
    case actionsTypes.RETRIEVE_COUNTRY_DATA_SUCCESS: return retrieveCountryDataSuccess( state, action );
    case actionsTypes.RETRIEVE_COUNTRY_DATA_FAIL: return retrieveCountryDataFail( state, action );

    default: return state;
  }
};

export default countryData;
