import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from 'components/HomeComponents/MyCard/MyCard.module.scss';
import ImageThumbnail from 'components/ImageThumbnail/ImageThumbnail';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const MyCard = ( props ) => {

  const {  t, i18n } = useTranslation();

  const [ pathPhotoThumbnail, setPathPhotoThumbnail ] = useState( null );
  const [ pathPhotoBig, setPathPhotoBig ] = useState( null );

  const { photo } = props.content;
  useEffect( () => {

    if ( photo ) {
      const pathCountryFolder = `${ process.env.CONTENT_LOCAL }/countries/${ photo.countryFolder }`;
      const pathJson = `${ pathCountryFolder }/${ photo.countryFolder }_${ i18n.language }.json`;

      axios.get( pathJson )
        .then( ( response ) => {
          console.log( '[MyCard useEffect] Success response=', response );

          const countryData = response.data;
          const photoDetails = countryData.pictures[ photo.name ];

          setPathPhotoThumbnail( `${ pathCountryFolder }/${photoDetails.originalSmall}` );
          setPathPhotoBig( `${ pathCountryFolder }/${photoDetails.originalBig}` );
        } )
        .catch( ( error ) => {
          console.log( '[MyCard useEffect] error during retrieveCountryData error=', error );
        } );
    }
    else {
      console.log( '[MyCard useEffect] no photo for this card');
    }


  }, [ photo, i18n.language ] );


  let image = null;
  if ( pathPhotoThumbnail && pathPhotoBig ) {
    image = (
      <ImageThumbnail
        title={ props.content.title }
        showTitle={ false }
        cardStyle
        urlThumbnail={ pathPhotoThumbnail }
        urlOriginal={ pathPhotoBig } />
      );
  }

  return (
    <div className={ classes.MyCard }>
      <h4 className={ classes.Title }>{ props.content.title }</h4>
      { image }
      <p>{ props.content.details }</p>
      <p className={ classes.CountryNote }>{ t( `UTILS.COUNTRY` ) }: { t( `COUNTRIES.${ props.content.country }` ) }</p>
    </div>
  );
};

MyCard.propTypes = {
  content: PropTypes.object
};

export default MyCard;
