import React from 'react';
import PropTypes from 'prop-types';
import classes from './NavigationItem.module.scss';

import { NavLink } from 'react-router-dom';

const NavigationItem = ( props ) => (
  <li className={ classes.NavigationItem }>
    <NavLink
      activeClassName={ classes.active }
      exact
      to={ props.link }>
      { props.children }
    </NavLink>
  </li>
);

NavigationItem.propTypes = {
  link: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default NavigationItem;
