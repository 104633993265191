import * as actionsTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import i18n from '../../translations/i18n';

const langFromLocalStorage = localStorage.getItem( 'language' );

export const initialState = {
  lang: langFromLocalStorage || 'en',
};

const changeLanguage = ( state, action ) => {

  console.log( 'changeLanguage' );

  i18n.changeLanguage( action.language );
  localStorage.setItem( 'language', action.language );
  return updateObject( state, {
    lang: action.language,
  } );
};

const language = ( state = initialState, action ) => {

  console.log( 'language reducer state=', state, '  action=', action );
  switch ( action.type ) {
    case actionsTypes.CHANGE_LANGUAGE: return changeLanguage( state, action );

    default: return state;
  }
};

export default language;
