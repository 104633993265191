import React, { useEffect, useState } from 'react';
import classes from './NavigationItems.module.scss';
import { useTranslation } from 'react-i18next';

import NavigationItem from './NavigationItem/NavigationItem';
import PropTypes from 'prop-types';
import Flag from 'components/UI/Flag/Flag';

const NavigationItems = ( props ) => {
  const { t } = useTranslation();

  const [ countryList, setCountryList ] = useState( null );
  const [ cssClasses, setCssClasses ] = useState( [ classes.NavigationItems ] );

  const { countries, toolBarDisplay } = props;
  useEffect( () => {

    console.log( `[NavigationItems] useEffect() toolBarDisplay=${ toolBarDisplay }, allCountries=`, countries );

    if ( !toolBarDisplay ) {
      setCountryList( countries );
    }
    else {
      setCssClasses( ( currState ) => {
        return currState.concat( classes.ToolbarDisplay );
      } );
    }
  }, [ toolBarDisplay, countries ] );

  let navigationItems = null;
  if ( countryList ) {
    navigationItems = countryList.map( ( country, index ) => {
      return (
        <NavigationItem key={ index } link={ country.route } toolBarDisplay={ props.toolBarDisplay }>
          <Flag width={ 45 } countryId={ country.id }/>
          <p>{ t( `COUNTRIES.${ country.id }` ) }</p>
        </NavigationItem>
      );
    } );
  }
  else {
    navigationItems = (
      <NavigationItem link="/" toolBarDisplay={ props.toolBarDisplay }>
        <p>{ t( 'NAV.HOME' ) }</p>
      </NavigationItem>
    );
  }

  return (
    <ul className={ cssClasses.join( ' ' ) }>
      { navigationItems }
    </ul>
  )
};

NavigationItems.propTypes = {
  isAuthenticated: PropTypes.bool,

  // NavigationItems are either displayed inside the toolbar on the top or in the sideDrawer on the left
  // Depending where they are displayed, the style is very different
  toolBarDisplay: PropTypes.bool,

  // List of country to be displayed as link on Mobile, if countries is null, it means we are using this component
  // to display the other links likeH ome or Quiz
  countries: PropTypes.array,
};

export default NavigationItems;
