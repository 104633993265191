export const updateObject = ( oldObject, updatedProperties ) => {
  return {
    ...oldObject,
    ...updatedProperties
  }
};

export const checkValidity = ( value, rules ) => {

  let isValid = true;

  if ( rules ) {
    if ( rules.required ) {
      isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if ( rules.isEmail ) {
      // const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      isValid = pattern.test( value ) && isValid;
    }

    if ( rules.maxLength ) {
      isValid = value.length <= rules.maxLength && isValid;
    }
  }


  return isValid;
};

export const randomIntFromInterval = ( min, max ) => {
  return Math.floor( Math.random() * ( max - min + 1 ) + min );
};

export const isObject = ( iObj ) => {
  return iObj instanceof Object && iObj.constructor === Object;
};

export const isArray = ( iArr ) => {
  return Array.isArray( iArr );
};

export const isDesktop = () => {
  return window.innerWidth > 1450
};
