import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './MyTypeahead.module.scss';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { Typeahead } from 'react-bootstrap-typeahead';

const MyTypeahead = ( props ) => {

  const [ currentSelected, setCurrentSelected ] = useState( [] );

  const clearSelection = () => {
    setCurrentSelected( [] );
  };

  const onSelectedHandler = ( selectedOptions ) => {
    console.log( '[MyTypeahead] - onSelectedHandler() - selectedOptions=', selectedOptions );
    if ( selectedOptions.length > 0 ) {
      props.onOptionSelected( selectedOptions[ 0 ].id, clearSelection );
    }

    setCurrentSelected( selectedOptions );

  };

  return (
    <div className={ classes.MyTypeahead }>
      <Typeahead
        id={ props.id }
        clearButton={ !props.hideClearButton }
        labelKey={ option => option.value }
        selectHintOnEnter
        onChange={ onSelectedHandler }
        options={ props.options }
        placeholder={ props.placeholder }
        selected={ currentSelected }
      />
    </div>
  );
};

MyTypeahead.propTypes = {
  // Array of options to display, each option is an object with { id, value }
  options: PropTypes.array,

  // id is require for screen readers
  id: PropTypes.string.isRequired,

  hideClearButton: PropTypes.bool,
  placeholder: PropTypes.string,
  onOptionSelected: PropTypes.func,
};

export default MyTypeahead;
