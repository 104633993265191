import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './ImageThumbnail.module.scss';
import Spinner from '../UI/Spinner/Spinner';
import Modal from '../../components/UI/Modal/Modal';
import Aux from '../../hoc/Aux/Aux';

const ImageThumbnail = ( props ) => {


  const [ showSpinner, setShowSpinner ] = useState( true );
  const [ showModal, setShowModal ] = useState( false );

  const handleImageLoaded = () => {
    setShowSpinner( false );
  };

  const handleImageErrored = () => {
    setShowSpinner( false );
  };

  const handleThumbnailClicked = () => {
    setShowModal( true );
  };

  const hideModal = () => {
    setShowModal( false );
  };

  let spinner = <Spinner/>;
  if ( !showSpinner ) {
    spinner = null;
  }

  let imageTitle = null;
  if ( props.showTitle ) {
    imageTitle = <p>{ props.title }</p>;
  }

  let modalContent = (
    <div className={ classes.ImageWithinModal }>
      <img src={ props.urlOriginal } alt={ props.title }/>
    </div>
  );

  const cssClasses = [ classes.ImageThumbnail ];
  if ( props.cardStyle ) {
    cssClasses.push( classes.CardStyle );
  }

  return (
    <Aux>
      <Modal
        onBackDropClicked={ hideModal }
        blackBackGround
        show={ showModal }>
        { modalContent }
      </Modal>
      <div
        className={ cssClasses.join( ' ' ) }
        onClick={ handleThumbnailClicked }>
        { spinner }
        <img
          onLoad={ handleImageLoaded }
          onError={ handleImageErrored }
          src={ props.urlThumbnail }
          alt={ props.title }/>
        { imageTitle }
      </div>
    </Aux>

  );
};

ImageThumbnail.propTypes = {
  urlThumbnail: PropTypes.string,
  urlOriginal: PropTypes.string,
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  cardStyle: PropTypes.bool,
};

export default ImageThumbnail;
