import React, { useEffect } from 'react';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

const Logout = ( props ) => {

  const { onLogout } = props;
  useEffect( () => {
    onLogout();
  }, [ onLogout ] );


  return (
    <Redirect to='/'/>
  );
};

Logout.propTypes = {};

const mapDispatchToPros = dispatch => {
  return {
    onLogout: () => dispatch( actions.logout() ),
  }
};

export default connect( null, mapDispatchToPros )( Logout );
