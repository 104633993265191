import React from 'react';
import PropTypes from 'prop-types';
import classes from './Toolbar.module.scss';
import Logo from '../../Logo/Logo';

import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import ChangeLanguage from '../ChangeLanguage/ChangeLanguage';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import MyTypeahead from '../../UI/MyTypeahead/MyTypeahead';
import { useTranslation } from 'react-i18next';

const Toolbar = ( props ) => {

  const { t } = useTranslation();

  const optionTypeahead = props.allCountries.map( ( country ) => {
    return {
      id: country.id,
      value: t( `COUNTRIES.${ country.id }` ),
    };
  } );

  const onCountrySelectedHandler = ( countryId, clearSelectionFn ) => {
    console.log( '[Toolbar] onCountrySelectedHandler() countryId=', countryId );

    props.history.push( {
      pathname: `/country/${ countryId }`
    } );

    setTimeout( () => {
      clearSelectionFn();
    }, 2000 );
  };

  return (
    <header className={ classes.Toolbar }>
      <div className={ classes.LeftToolbar }>
        <div className={ classes.Logo }>
          <NavLink
            exact
            to='/'>
            <Logo/>
          </NavLink>
        </div>
        <ChangeLanguage/>
      </div>
      <DrawerToggle clicked={ props.toggleSideDrawer }/>

      <nav className={ 'd-none d-lg-block' }>
        <div>
          <MyTypeahead
            id="select-country-typeahead"
            options={ optionTypeahead }
            placeholder={ t( 'NAV.SELECT_COUNTRY' ) }
            onOptionSelected={ onCountrySelectedHandler }
          />

        </div>
      </nav>
    </header>
  );
};

Toolbar.propTypes = {
  toggleSideDrawer: PropTypes.func,
  isAuth: PropTypes.bool,
  allCountries: PropTypes.array
};

export default withRouter( Toolbar );
