export const allCountries = [
  {
    id: 'chile',
    continentId: 'southAmerica',
  },
  {
    id: 'bolivia',
    continentId: 'southAmerica',
  },
  {
    id: 'peru',
    continentId: 'southAmerica',
  },
  {
    id: 'ecuador',
    continentId: 'southAmerica',
  },
  {
    id: 'colombia',
    continentId: 'southAmerica',
  },
  {
    id: 'panama',
    continentId: 'northAmerica',
  },
  {
    id: 'guatemala',
    continentId: 'northAmerica',
  },
  {
    id: 'belize',
    continentId: 'northAmerica',
  },
  {
    id: 'mexico',
    continentId: 'northAmerica',
  },
  {
    id: 'usaWestCoast',
    continentId: 'northAmerica',
  },
  {
    id: 'usaNationalParks',
    continentId: 'northAmerica',
  },
  {
    id: 'southKorea',
    continentId: 'asia',
  },
  {
    id: 'japan',
    continentId: 'asia',
  },
  {
    id: 'taiwan',
    continentId: 'asia',
  },
  {
    id: 'hongKongMacau',
    continentId: 'asia',
  },
  {
    id: 'india',
    continentId: 'asia',
  },
  {
    id: 'vietnam',
    continentId: 'asia',
  },
  {
    id: 'laos',
    continentId: 'asia',
  },
  {
    id: 'thailandIsland',
    continentId: 'asia',
  },
  {
    id: 'myanmar',
    continentId: 'asia',
  },
  {
    id: 'thailandRemi',
    continentId: 'asia',
  },
];

export const allCountriesObject = allCountries.map( ( country ) => {
  return {
    ...country,
    route: `/country/${ country.id }`
  }
});
