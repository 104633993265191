import * as actionsTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

export const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  authRedirect: '/'
};

const authStart = ( state ) => {
  return updateObject( state, {
    loading: true,
    error: null
  } );
};

const authSuccess = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    token: action.token,
    userId: action.userId,
    error: null,
  } );
};

const authFail = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    error: action.error
  } );
};

const logout = ( state ) => {
  return updateObject( state, {
    loading: false,
    error: null,
    token: null,
    userId: null,
  } );
};

const setAuthRedirect = ( state, action ) => {
  return updateObject( state, {
    authRedirect: action.path
  } );
};


const auth = ( state = initialState, action ) => {

  switch ( action.type ) {
    case actionsTypes.AUTH_START: return authStart( state );
    case actionsTypes.AUTH_SUCCESS: return authSuccess( state, action );
    case actionsTypes.AUTH_FAIL: return authFail( state, action );
    case actionsTypes.AUTH_LOGOUT: return logout( state );
    case actionsTypes.SET_AUTH_REDIRECT: return setAuthRedirect( state, action );

    default: return state;
  }
};

export default auth;
