
const locale_fr = {
  translation: {
    ORDERS: 'Commandes',
    NAV: {
      HOME: 'Accueil',
      SELECT_COUNTRY: 'Choisir un pays...'
    },
    COUNTRIES: {
      chile: 'Chili',
      bolivia: 'Bolivie',
      peru: 'Pérou',
      ecuador: 'Équateur',
      colombia: 'Colombie',
      panama: 'Panama',
      guatemala: 'Guatemala',
      belize: 'Belize',
      mexico: 'Mexique',
      usa: 'USA',
      usaWestCoast: 'USA - côte ouest',
      usaNationalParks: 'USA - parcs nationaux',
      southKorea: 'Corée du Sud',
      japan: 'Japon',
      taiwan: 'Taïwan',
      hongKongMacau: 'Hong Kong - Macau',
      india: 'Inde',
      vietnam: 'Vietnam',
      laos: 'Laos',
      singapore: 'Singapour',
      thailand: 'Thaïlande',
      thailandIsland: 'Thaïlande - Îles du sud-ouest',
      thailandRemi: 'Thaïlande avec Rémi',
      myanmar: 'Birmanie',
    },

    CONTINENTS: {
      southAmerica: 'Amérique du Sud',
      northAmerica: 'Amérique du Nord',
      asia: 'Asie',
    },


    UTILS: {
      DOWNLOAD_PHOTOS: 'Télécharger toutes les photos',
      LOADING: 'Chargement',
      COUNTRY: 'Pays'
    },

  }
};

export default locale_fr;
