import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

export const purchaseBurgerSuccess = ( id, orderData ) => {
  return {
    type: actionTypes.PURCHASE_BURGER_SUCCESS,
    orderId: id,
    orderData
  };
};


export const purchaseBurgerFail = ( error ) => {
  return {
    type: actionTypes.PURCHASE_BURGER_FAIL,
    error
  };
};

export const purchaseBurgerStart = () => {
  return {
    type: actionTypes.PURCHASE_BURGER_START
  }
};

export const purchaseBurger = ( orderData, token ) => {
  return dispatch => {

    dispatch( purchaseBurgerStart() );

    axios.post( `/orders.json?auth=${ token }`, orderData )
      .then( ( response ) => {
        console.log( '[BurgerBuilder] purchaseContinueHandler response=', response );
        dispatch( purchaseBurgerSuccess( response.data.name, orderData ) );
      } )
      .catch( ( error ) => {
        console.error( '[BurgerBuilder] purchaseContinueHandler error=', error );
        dispatch( purchaseBurgerFail( error ) );
      } );
  }
};

export const purchaseInit = () => {
  return {
    type: actionTypes.PURCHASE_INIT
  }
};


export const fetchOrdersStart = () => {
  return {
    type: actionTypes.FETCH_ORDERS_START,
  }
};

export const fetchOrdersSuccess = ( orders ) => {
  return {
    type: actionTypes.FETCH_ORDERS_SUCCESS,
    orders

  }
};

export const fetchOrdersFail = ( error ) => {
  return {
    type: actionTypes.FETCH_ORDERS_FAIL,
    error
  }
};

export const fetchOrders = ( token, userId ) => {
  return dispatch => {
    dispatch( fetchOrdersStart() );

    const queryParams = `?auth=${ token }&orderBy="userId"&equalTo="${ userId }"`;

    axios.get( `/orders.json${ queryParams }` )
      .then( ( res ) => {
        console.log( '[Reducer orders] orders=', res.data );

        const fetchOrders = [];
        for ( let [ orderId, order ] of Object.entries( res.data ) ) {
          console.log( '[Reducer orders] order=', order, ' orderId=', orderId );
          fetchOrders.push( {
            ...order,
            id: orderId
          } );
        }

        dispatch( fetchOrdersSuccess( fetchOrders ) );

      } )
      .catch( ( err ) => {
        console.error( '[Reducer orders] error fetching data err=', err );
        dispatch( fetchOrdersFail( err ) );
      } );
  }
};
