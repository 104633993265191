const locale_en = {
  translation: {
    ORDERS: 'Orders',
    NAV: {
      HOME: 'Home',
      SELECT_COUNTRY: 'Select country...'
    },
    COUNTRIES: {
      chile: 'Chile',
      bolivia: 'Bolivia',
      peru: 'Peru',
      ecuador: 'Ecuador',
      colombia: 'Colombia',
      panama: 'Panama',
      guatemala: 'Guatemala',
      belize: 'Belize',
      mexico: 'Mexico',
      usa: 'USA',
      usaWestCoast: 'USA - west coast',
      usaNationalParks: 'USA - national parks',
      southKorea: 'South Korea',
      japan: 'Japan',
      taiwan: 'Taiwan',
      hongKongMacau: 'Hong Kong - Macau',
      india: 'India',
      vietnam: 'Vietnam',
      laos: 'Laos',
      singapore: 'Singapore',
      thailand: 'Thailand',
      thailandIsland: 'Thailand - south west islands',
      thailandRemi: 'Thailand with Rémi',
      myanmar: 'Myanmar',
    },

    CONTINENTS: {
      southAmerica: 'South America',
      northAmerica: 'North America',
      asia: 'Asia',
    },

    UTILS: {
      DOWNLOAD_PHOTOS: 'Download all photos',
      LOADING: 'Loading',
      COUNTRY: 'Country'
    },
  }
};

export default locale_en;
