import React from 'react';
import PropTypes from 'prop-types';
import classes from './Modal.module.scss';
import Aux from '../../../hoc/Aux/Aux';
import Backdrop from '../Backdrop/Backdrop';

const Modal = ( props ) => {

  const cssClasses = [ classes.Modal ];
  if ( props.blackBackGround ) {
    cssClasses.push( classes.Black );
  }

  return (
    <Aux>
      <Backdrop clicked={ props.onBackDropClicked } show={ props.show }/>
      <div
        className={ cssClasses.join( ' ' ) }
        style={ {
          transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
          opacity: props.show ? 1 : 0,
        } }>
        { props.children }
      </div>
    </Aux>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  blackBackGround: PropTypes.bool,
  onBackDropClicked: PropTypes.func,
};

export default React.memo( Modal, ( prevProps, nextProps) => {
  return nextProps.show === prevProps.show && nextProps.children === prevProps.children;

} );
