import React from 'react';
import './Spinner.scss';
import { useTranslation } from 'react-i18next';

const Spinner = ( props ) => {

  const { t } = useTranslation();

  return (
    <div className="spinner-container">
      <div className="loader">{ t( 'UTILS.LOADING') }...</div>
    </div>
  );
};

Spinner.propTypes = {};

export default Spinner;
