import * as actionsTypes from './actionTypes';
import axios from 'axios';

const FIREBASE_API_KEY = 'AIzaSyASfdJ8FTOqGRz58q6tq07WtD9sajKX_-E'


export const authStart = () => {
  return {
    type: actionsTypes.AUTH_START,
  };
};

export const authSuccess = ( token, userId ) => {
  return {
    type: actionsTypes.AUTH_SUCCESS,
    token,
    userId
  };
};


export const authFail = ( error ) => {
  return {
    type: actionsTypes.AUTH_FAIL,
    error
  };
};

export const logout = () => {
  localStorage.removeItem( 'token' );
  localStorage.removeItem( 'expirationDate' );
  localStorage.removeItem( 'userId' );
  return {
    type: actionsTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = ( expirationTime ) => {
  return dispatch => {
    setTimeout( () => {
      dispatch( logout() );
    }, expirationTime * 1000 )
  }
};

export const auth = ( email, password, isSignUp ) => {
  return dispatch => {
    dispatch( authStart() );

    const authData = {
      email,
      password,
      returnSecureToken: true
    };

    let url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=${ FIREBASE_API_KEY }`;

    if ( !isSignUp ) {
      url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=${ FIREBASE_API_KEY }`;
    }

    axios.post( url, authData )
      .then( ( response ) => {
        console.log( '[auth actions] Success signup response=', response );

        const idToken = response.data.idToken;
        const userId = response.data.localId;

        const expirationDate = new Date( new Date().getTime() + response.data.expiresIn * 1000 );
        localStorage.setItem( 'token', idToken );
        localStorage.setItem( 'expirationDate', expirationDate );
        localStorage.setItem( 'userId', userId );

        dispatch( authSuccess( idToken, userId ) );
        dispatch( checkAuthTimeout( response.data.expiresIn ) )
      } )
      .catch( ( error ) => {
        console.error( 'error during authentication error=', error );
        dispatch( authFail( error.response.data.error ) );
      } );
  };
};

export const setAuthRedirect = ( path ) => {
  return {
    type: actionsTypes.SET_AUTH_REDIRECT,
    path
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem( 'token' );
    if ( !token ) {
      dispatch( logout() );
    }
    else {
      const expirationDate = new Date( localStorage.getItem( 'expirationDate' ) );

      const dateNow = new Date();
      if ( expirationDate > dateNow ) {
        const userId = localStorage.getItem( 'userId' );
        dispatch( authSuccess( token, userId ) );
        dispatch( checkAuthTimeout( ( expirationDate.getTime() - dateNow.getTime() ) / 1000 ) );
      }
      else {
        dispatch( logout() );
      }


    }


  };
};

