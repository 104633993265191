import React, { useEffect, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions';

import Layout from './containers/Layout/Layout';
import Home from './containers/Home/Home';
import Logout from './containers/Auth/Logout/Logout';

import { allCountriesObject } from './shared/countryList';

const Checkout = React.lazy( () => {
  return import( './containers/Checkout/Checkout' );
} );

const Orders = React.lazy( () => {
  return import( './containers/Orders/Orders' );
} );

const Auth = React.lazy( () => {
  return import( './containers/Auth/Auth' );
} );

const Country = React.lazy( () => {
  return import( './containers/Country/Country' );
} );

const App = ( props ) => {

  useEffect( () => {
    console.log( `[App] useEffect() - props=`, props );
    props.onTryAutoSignUp();
  }, [ props ] );


  let routesCountry = allCountriesObject.map( ( country ) => {
    return (
      <Route key={ country.id } path={ country.route } render={ () => <Country id={ country.id }/> }/>
    )
  } );

  let routes = (
    <Switch>
      { routesCountry }
      <Route path="/" exact component={ Home }/>
      <Redirect to="/"/>
    </Switch>
  );

  if ( props.isAuthenticated ) {
    routes = (
      <Switch>
        <Route path="/logout" component={ Logout }/>
        <Route path="/checkout" component={ Checkout }/>
        <Route path="/auth" component={ Auth }/>
        <Route path="/orders" component={ Orders }/>
        <Route path="/" exact component={ Home }/>
        <Redirect to="/"/>
      </Switch>
    );
  }

  return (
    <div>
      <Layout>
        <Suspense fallback={ <p>Loading...</p> }>
          { routes }
        </Suspense>
      </Layout>
    </div>
  );

};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
  }
};
const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignUp: () => dispatch( actions.authCheckState() )
  }
};


export default connect( mapStateToProps, mapDispatchToProps )( App );
