import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import classes from './Home.module.scss';
// import PropTypes from 'prop-types';

import FinalItinerary from 'assets/images/Final_Itinerary.jpg';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/UI/Spinner/Spinner';

import MyCard from 'components/HomeComponents/MyCard/MyCard'


import HomeContentFr from './Home.content.fr';
import HomeContentEn from './Home.content.en';

const homeContent = {
  en: HomeContentEn,
  fr: HomeContentFr,
};


const Home = ( props ) => {

  const { i18n } = useTranslation();

  const [ content, setContent ] = useState( null );

  useEffect( () => {
      setContent( homeContent[ i18n.language ] );
  }, [ i18n.language ] );

  let homePageContent = <Spinner/>;
  if ( content ) {
    homePageContent = (
      <Container className={ [ classes.Container, classes.Home ].join( ' ' ) }>
        <Row>
          <Col className={ classes.Content }>
            <h1>{ content.TITLE }</h1>
            { content.INTRO.map( (line, index) => (
            <p key={ index }>{ line }</p>
            ) ) }

            <hr />

            <h1>{ content.ITINERARY_TITLE }</h1>
            <img className={ classes.WorldMap } src={ FinalItinerary } alt='Final Itinerary'/>

          </Col>
        </Row>
        <Row>
          { content.THINGS_TO_REMEMBER.map( ( thing, index ) => {
            return (
              <Col key={ index } xs={ 6 } sm={ 6 } md={ 4 }  lg={ 3 } className={ classes.Cards }>
                <MyCard content={ thing }></MyCard>
              </Col>
            );
          } ) }
        </Row>
      </Container>
    );
  }


  return (
    <div>
      { homePageContent }
    </div>
  );
};

Home.propTypes = {};

export default Home;
